import React, { createContext, useContext, useRef } from 'react';
import {
  VistaAssetStore,
  SubBrands,
  Tenants,
} from '@design-stack-vista/vista-assets-sdk';
import { IdentityContext } from '../../context/IdentityContext';
import { getLogger } from '@vp/digital-logging-lib';

interface AssetStoreContextModel {
  assetStore: any;
  uploadAsset: (
    file: File,
    progressBarPercentage: any
  ) => Promise<uploadAssetModel>;
}

interface uploadAssetModel {
  publicUrl: string;
  fileName: string;
  fileType: string;
  fileSize: string;
}

type AssetStoreProps = {
  children: React.ReactNode;
};

const AssetStoreContext = createContext<AssetStoreContextModel>(
  {} as AssetStoreContextModel
);

const AssetStoreProvider: React.FC = ({ children }: AssetStoreProps) => {
  const { canonicalUser } = useContext(IdentityContext);
  const { current: logger } = useRef(getLogger({ page: 'assetStoreProvider' }));
  const authProvider = {
    getAuthHeaders: async () => ({
      Authorization: `Bearer ${canonicalUser?.authToken}`,
    }),
  };
  const uploadAsset = async (
    file: File,
    progressPercentage: any
  ): Promise<uploadAssetModel> => {
    try {
      const onProgress = (percentage: number) => progressPercentage(percentage);

      const asset = await assetStore.upload({
        file,
        onProgress,
        expires: 'never',
      });
      const fileName = asset.data.info.storage.fileName;
      await asset.presign();
      const publicUrl = asset.getUrl({ includeSignature: true });
      const fileType = asset.data.info.storage.contentType.substring(
        0,
        asset.data.info.storage.contentType.indexOf('/')
      );
      const fileSize =
        (asset.data.info.storage.fileSizeBytes / 1000).toString() + ' KB';
      return { publicUrl, fileName, fileType, fileSize };
    } catch (e) {
      logger.error('error while uploading assets ');
      return undefined;
    }
  };

  const assetStore = new VistaAssetStore({
    authProvider,
    subBrand: SubBrands.VistaPrint,
    experience: 'VistaXWixDiwy',
    writeTenant:
      process.env.GATSBY_ENVIRONMENT === 'production'
        ? Tenants.VistaPrint
        : Tenants.VistaNonProd,
  });

  return (
    <AssetStoreContext.Provider value={{ assetStore, uploadAsset }}>
      {children}
    </AssetStoreContext.Provider>
  );
};

export { AssetStoreProvider, AssetStoreContext };
