// File to define common Gatsby browser & ssr function(s)
import React, { useContext, useEffect } from 'react';
import { LocaleProvider } from '@vp/digital-locale-lib';
import {
  RuntimeContextProvider,
  getClientEnvironment,
} from '@vp/digital-environment-lib';
import { UrlContextProvider } from '@vp/digital-url-context-lib';
import { RootLayout } from '@vp/digital-site-layout-lib';
import { StyleKeys, ScriptKeys } from './src/swanKeys';
import './gatsby-common.scss';
import './src/components/diwyHeader/diwyHeader.scss';
import './src/components/helpBar/helpBar.scss';
import { BookendsWrapper } from '@vp/digital-bookends-lib';
import { AssetStoreProvider } from './src/components/hooks/AssetStoreProvider';
import { IdentityProvider } from './src/context/IdentityContext';
import { SiteContextProvider } from './src/context/SiteContext';
import { AuthProvider } from '@vp/digital-auth-lib';
import { BuildTimeData } from './src/routing/BuildTimeData';
import { ScreenClassProvider } from '@vp/swan';
import { Helmet } from 'react-helmet';
import { LinkToPage } from './src/routing/LinkToPage';
import { UrlId } from './src/routing/ExternalUrlId';
import { StringParam, useQueryParam } from 'use-query-params';
import Loading from './src/components/common/Loading';
import {
  PricingContentProvider,
  PricingContextProvider,
} from '@vp/digital-pricing-lib';
const ProductPageWrapper = ({ pageContext, location, children }) => {
  const {
    locale,
    billingPeriods,
    trackingConfiguration,
    clientId,
    externalUrls,
    directAccessConnection,
    isInternalUserView,
    hideFooter,
    hideHeader,
    requireSiteAltId,
    requireAuth,
  } = pageContext;

  const { DiwyUrls } = useContext(BuildTimeData);
  const [siteAltId] = useQueryParam('siteAltId', StringParam);
  const [isInternal] = useQueryParam('isInternal', StringParam);
  const [debug] = useQueryParam('debug', StringParam);
  useEffect(() => {
    if (!debug && requireSiteAltId === true && !siteAltId) {
      LinkToPage(UrlId.DIWY_CONSOLE_NOT_FOUND, DiwyUrls, locale);
    }
  }, [siteAltId]);
  if (!debug && requireSiteAltId === true && !siteAltId) {
    return <Loading />;
  }
  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      {billingPeriods ? (
        <PricingContextProvider>
          <PricingContentProvider pricingContent={billingPeriods}>
            <RootLayout swanScriptKeys={ScriptKeys} swanStyleKeys={StyleKeys}>
              <BookendsWrapper
                tenant="vistaprint"
                locale={locale}
                renderMetaWith={Helmet}
                trackingConfiguration={trackingConfiguration}
                hideFooter={hideFooter}
                hideHeader={hideHeader}
                displayCartIcon={false}
              >
                <RuntimeContextProvider getEnvironment={getClientEnvironment}>
                  <AuthProvider
                    directAccessClientId={
                      isInternalUserView || isInternal === 'true'
                        ? clientId[1]
                        : clientId[0]
                    }
                    directAccessConnection={
                      isInternalUserView || isInternal === 'true'
                        ? directAccessConnection
                        : undefined
                    }
                  >
                    <IdentityProvider
                      isInternalUserView={
                        isInternalUserView || isInternal === 'true'
                      }
                      requireAuth={
                        requireAuth != null || undefined ? requireAuth : true
                      }
                    >
                      <AssetStoreProvider>
                        <UrlContextProvider location={location}>
                          <BuildTimeData.Provider
                            value={{
                              DiwyUrls: externalUrls,
                            }}
                          >
                            <SiteContextProvider>
                              <ScreenClassProvider>
                                {children}
                              </ScreenClassProvider>
                            </SiteContextProvider>
                          </BuildTimeData.Provider>
                        </UrlContextProvider>
                      </AssetStoreProvider>
                    </IdentityProvider>
                  </AuthProvider>
                </RuntimeContextProvider>
              </BookendsWrapper>
            </RootLayout>
          </PricingContentProvider>
        </PricingContextProvider>
      ) : (
        <RootLayout swanScriptKeys={ScriptKeys} swanStyleKeys={StyleKeys}>
          <BookendsWrapper
            tenant="vistaprint"
            locale={locale}
            renderMetaWith={Helmet}
            trackingConfiguration={trackingConfiguration}
            hideFooter={hideFooter}
            hideHeader={hideHeader}
            displayCartIcon={false}
          >
            <RuntimeContextProvider getEnvironment={getClientEnvironment}>
              <AuthProvider
                directAccessClientId={
                  isInternalUserView || isInternal === 'true'
                    ? clientId[1]
                    : clientId[0]
                }
                directAccessConnection={
                  isInternalUserView || isInternal === 'true'
                    ? directAccessConnection
                    : undefined
                }
              >
                <IdentityProvider
                  isInternalUserView={
                    isInternalUserView || isInternal === 'true'
                  }
                >
                  <AssetStoreProvider>
                    <UrlContextProvider location={location}>
                      <BuildTimeData.Provider
                        value={{
                          DiwyUrls: externalUrls,
                        }}
                      >
                        <SiteContextProvider>
                          <ScreenClassProvider>
                            <Helmet htmlAttributes={{ lang: locale }} />{' '}
                            {children}
                          </ScreenClassProvider>
                        </SiteContextProvider>
                      </BuildTimeData.Provider>
                    </UrlContextProvider>
                  </AssetStoreProvider>
                </IdentityProvider>
              </AuthProvider>
            </RuntimeContextProvider>
          </BookendsWrapper>
        </RootLayout>
      )}
    </LocaleProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { wrap },
  } = props;
  return wrap ? (
    <ProductPageWrapper {...props}>{element}</ProductPageWrapper>
  ) : (
    element
  );
};
