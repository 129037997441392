export enum UrlId {
  DIWY_CONSOLE = 'digital-merchandising:diwy-console',
  DIWY_CONSOLE_NOT_FOUND = 'digital-merchandising:diwy-console-not-found',
  DIWY_INTAKE_FORM = 'digital-merchandising:diwy-intake-form',
  DIWY_NET_NEW_INTAKE_FORM = 'digital-merchandising:diwy-net-new-intake-form',
  DIWY_APPOINTMENT = 'digital-merchandising:diwy-appointment',
  DIWY_APPOINTMENT_CONFIRMATION = 'digital-merchandising:diwy-appointment-confirmation',
  DIWY_START = 'digital-merchandising:diwy-start-trial',
  DIWY_BRIEF = 'digital-merchandising:diwy-brief',
  WORKSPACE = 'workspace:website-dashboard',
  DIWY_CARE = 'digital-merchandising:diwy-care',
  DIWY_PLANS = 'digital-merchandising:diwy-plans',
  DIWY_WIW_SITE_SELECTOR = 'digital-merchandising:diwy-wix-site-selector',
  MANAGE_SUBSCRIPTIONS = 'subscriptions:manage',
  CHANGE_SUBSCRIPTIONS = 'subscriptions:changePlan',
}
