import { AxiosRequestConfig } from 'axios';
import { NullLogger } from '@vp/digital-logging-lib';
import {
  HttpApiConfigModel,
  HttpApiOperationConfigModel,
  HttpClientDispenser,
} from '@vp/digital-http-client-lib';

export async function handleHttpResponse<
  R,
  A extends HttpApiConfigModel = HttpApiConfigModel,
  O extends HttpApiOperationConfigModel = HttpApiOperationConfigModel
>(
  axiosConfig: AxiosRequestConfig,
  clientDispenser: HttpClientDispenser<A, O>,
  message?: string,
  error?: string
): Promise<R> {
  const logger = new NullLogger();
  const response = await clientDispenser
    .getInstance()
    .request<any>(axiosConfig);
  if (response.status >= 200 || response.status < 300) {
    logger.info(message || 'Request successful: ', response.data);
    return response.data as R;
  } else {
    logger.error(error || 'Error while executing request: ', response.data);
    throw new Error('Something went wrong, please reload the page!');
  }
}
