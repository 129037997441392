import { Config } from './EnvironmentConfigModels';

export const prodConfig: Config = {
  diwyOrigin: 'https://wix-diwy.digital.vpsvc.com',
};

export const stagingConfig: Config = {
  diwyOrigin: 'https://qa.wix-diwy.digital.vpsvc.com',
};

export const localConfig: Config = {
  //diwyOrigin: 'http://localhost:8081',
  diwyOrigin: 'https://qa.wix-diwy.digital.vpsvc.com',
};
