import { getClientEnvironment, Environment } from '@vp/digital-environment-lib';
import { Config } from './EnvironmentConfigModels';
import { prodConfig, stagingConfig, localConfig } from './EnvironmentConfig';

function getClientConfig(): Config {
  const env: Environment = getClientEnvironment();
  switch (env) {
    case Environment.Prod:
      return prodConfig;
    case Environment.Staging:
      return stagingConfig;
    case Environment.Local:
      return localConfig;
    default:
      break;
  }
  throw new Error('Cannot determine which environment to use');
}

export default getClientConfig;
