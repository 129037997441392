import { AxiosRequestConfig } from 'axios';
import { HttpClientDispenser } from '@vp/digital-http-client-lib';
import {
  DiwyServiceSiteHttpApiConfigModel,
  GetHttpApiOperationConfigModel,
} from '../HttpClientConfig.model';
import defaultConfig from '../DiwyHttpClientDefaultConfig';
import { handleHttpResponse } from '../common/http-helper';
import { SiteRequest } from '../../models/SiteModel';
import { GetSiteResponseInterface } from '../../models/types/SiteAggregateInterface';

const apiConfig: DiwyServiceSiteHttpApiConfigModel = defaultConfig.siteApi;
const opConfig: GetHttpApiOperationConfigModel =
  apiConfig.operations.manageSite;
const projectClientDispenser = new HttpClientDispenser(apiConfig, opConfig);

const BASE_PATH = '/api/v1/site';

export async function getSite(
  siteAltId: String,
  authToken: String,
  isCare?: boolean,
  canonicalId?: string
): Promise<GetSiteResponseInterface> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}/${siteAltId}`,
    method: 'GET',
    headers: {
      canonicalId: canonicalId,
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
    params: {
      isCare: isCare,
    },
  };

  return handleHttpResponse<GetSiteResponseInterface>(
    axiosConfig,
    projectClientDispenser,
    `Getting Site data Successfully for siteAltId : ${siteAltId}`,
    `Error while getting Site data for siteAltId: ${siteAltId}`
  );
}

export async function getAllSites(
  canonicalId: string,
  authToken: String
): Promise<Array<GetSiteResponseInterface>> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}`,
    method: 'GET',
    headers: {
      canonicalId: canonicalId,
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  };

  return handleHttpResponse<Array<GetSiteResponseInterface>>(
    axiosConfig,
    projectClientDispenser
  );
}

export async function getAllSitesWixSiteId(
  canonicalId: string,
  authToken: String
): Promise<Array<GetSiteResponseInterface>> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}/wixSiteIds`,
    method: 'GET',
    headers: {
      canonicalId: canonicalId,
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  };

  return handleHttpResponse<Array<GetSiteResponseInterface>>(
    axiosConfig,
    projectClientDispenser
  );
}

export async function getWixSitesMetadata(
  body: any,
  canonicalId: string,
  authToken: String
): Promise<Array<any>> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}/premium-sites-metadata`,
    method: 'POST',
    data: body,
    headers: {
      canonicalId: canonicalId,
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  };

  return handleHttpResponse<Array<GetSiteResponseInterface>>(
    axiosConfig,
    projectClientDispenser
  );
}

export async function getSiteUpgradeDate(
  canonicalId: string,
  authToken: String,
  wixSiteId: String
): Promise<Array<any>> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}/change-plan/${wixSiteId}`,
    method: 'GET',
    headers: {
      canonicalId: canonicalId,
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  };

  return handleHttpResponse<Array<GetSiteResponseInterface>>(
    axiosConfig,
    projectClientDispenser
  );
}

export async function updateSite(
  siteAltId: String,
  body: SiteRequest,
  authToken: String
): Promise<GetSiteResponseInterface> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}/${siteAltId}`,
    method: 'PUT',
    data: body,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  };

  return handleHttpResponse<GetSiteResponseInterface>(
    axiosConfig,
    projectClientDispenser,
    `Site updated Successfully for siteAltId : ${siteAltId}`,
    `Error while updating Site for siteAltId: ${siteAltId}`
  );
}

export async function updateSiteByWixSiteId(
  wixSiteId: String,
  body: SiteRequest,
  authToken: String
): Promise<GetSiteResponseInterface> {
  const axiosConfig: AxiosRequestConfig = {
    url: `${BASE_PATH}/wixSiteId/${wixSiteId}`,
    method: 'PUT',
    data: body,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
  };

  return handleHttpResponse<GetSiteResponseInterface>(
    axiosConfig,
    projectClientDispenser,
    `Site updated Successfully for wixSiteId : ${wixSiteId}`,
    `Error while updating Site for wixSiteId: ${wixSiteId}`
  );
}
