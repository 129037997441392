import { RetryPolicy } from '@vp/digital-http-client-lib';
import { DiwyServiceLibConfigModel } from './HttpClientConfig.model';
import getClientConfig from '../config/EnvironmentConfigManager';
import { v4 as uuidv4 } from 'uuid';

const commonConfig = {
  timeoutMS: 5 * 1000,
  retry: {
    policy: RetryPolicy.ExponentialBackOff,
    count: 3,
  },
  cache: {
    maxAgeMS: 60 * 1000,
    maxSize: 1000,
  },
  parameters: {
    requestor: 'Digital',
  },
};

const defaultConfig: DiwyServiceLibConfigModel = {
  briefCreationApi: {
    baseUrl: getClientConfig().diwyOrigin,
    correlationId: uuidv4(),
    operations: {
      briefCreation: commonConfig,
    },
  },
  projectApi: {
    baseUrl: getClientConfig().diwyOrigin,
    correlationId: uuidv4(),
    operations: {
      manageProject: commonConfig,
    },
  },
  consultationApi: {
    baseUrl: getClientConfig().diwyOrigin,
    correlationId: uuidv4(),

    operations: {
      manageConsultationt: commonConfig,
    },
  },
  siteApi: {
    baseUrl: getClientConfig().diwyOrigin,
    correlationId: uuidv4(),
    operations: {
      manageSite: commonConfig,
    },
  },

  careApi: {
    baseUrl: getClientConfig().diwyOrigin,

    correlationId: uuidv4(),

    operations: {
      addUser: commonConfig,
    },
  },

  targetApi: {
    baseUrl: getClientConfig().diwyOrigin,
    correlationId: uuidv4(),

    operations: {
      getTargetSite: commonConfig,
    },
  },
};

export default defaultConfig;
