import React from 'react';
import LocaldiwyUrls from './localUrls.json';
import { useLocale } from '@vp/digital-locale-lib';
import { Environment, getClientEnvironment } from '@vp/digital-environment-lib';

interface Props {
  urlId: string;
  diwyUrls: Array<any> | undefined;
  params?: any;
}

const environment = getClientEnvironment();

export const LinkToPage = (
  urlId: any,
  diwyUrls: any,
  locale: any,
  params: any = '',
  isInternal?: string
) => {
  const defaultLocale = locale || 'en-US';
  if (params !== '' && isInternal === 'true') {
    params = params + '&isInternal=true';
  }
  let url: string;
  if (
    ([Environment.Staging].includes(environment) &&
      window.location.href.includes('staging')) ||
    [Environment.Prod].includes(environment)
  ) {
    //Prodcution or Staging

    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url = diwyUrls[locale][urlId].url + params;
  } else if (window.location.href.includes(process.env.GATSBY_DIWY_QA_LINK)) {
    // @ts-ignore
    url = `/pages/${defaultLocale.toLowerCase()}/${LocaldiwyUrls[defaultLocale][
      urlId
    ].toString()}/index.html${params}`;
  } else {
    // @ts-ignore
    url = `/pages/${defaultLocale.toLowerCase()}/${LocaldiwyUrls[defaultLocale][
      urlId
    ].toString()}${params}`;
  }

  window.location.assign(url);
};
