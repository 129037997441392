import React, { useState, useEffect, useContext } from 'react';
import { SiteApiResponseInterface } from '../models/types/SiteApiResponseInterface';
import { getSite } from '../http/site/SiteClient';
import { IdentityContext } from './IdentityContext';
import { StringParam, useQueryParam } from 'use-query-params';

interface SiteContextModel {
  userSite: SiteApiResponseInterface;
}

const defaultContextValue: SiteContextModel = {
  userSite: {} as SiteApiResponseInterface,
};

const SiteContext = React.createContext<SiteContextModel>(defaultContextValue);

type SiteProviderProps = {
  children: React.ReactNode;
  siteAltId: string;
  authToken: string;
  canonicalId: string;
};

const SiteContextProvider: React.FC<SiteProviderProps> = ({ children }) => {
  const [userSite, setUserSite] = useState<SiteApiResponseInterface>(
    defaultContextValue.userSite
  );

  const { canonicalUser } = useContext(IdentityContext);
  const [siteAltId] = useQueryParam('siteAltId', StringParam);

  useEffect(() => {
    const fetchSite = async () => {
      try {
        const site = await getSite(
          siteAltId,
          canonicalUser.authToken,
          false,
          canonicalUser.canonicalId
        );
        setUserSite(site);
      } catch (error) {
        console.error('Error fetching site:', error);
      }
    };
    if (siteAltId) {
      fetchSite();
    }
  }, [siteAltId, canonicalUser]);

  return (
    <SiteContext.Provider value={{ userSite }}>{children}</SiteContext.Provider>
  );
};

export { SiteContext, SiteContextProvider };
